/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-08-21 20:27:23
 * */
import Header from "../../components/global/header";
import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-js-pagination";
import methodModel from "../../methods/methods";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss";
import { Link } from "react-router-dom";

export default function ProjectsList() {
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [ProjectsData, setProjectsData] = useState([]);
  const [ViewProjectData, setViewProjectData] = useState({});
  console.log(ViewProjectData,'==ViewProjectData');
  
  const [data, setData] = useState([]);
  const [currentDate, setcurrentDate] = useState(new Date());
  const [profileCompletion, setProfileCompletion] = useState(50);
  const [JobLoading, setJobLoading] = useState(false);
  const [Filters, setFilters] = useState({
    search: "",
    page: 1,
    count: 50,
    skills: [],
    technology: [],
    country: [],
    status: "active",
    userId: user.id,
  });
  const [total, setTotal] = useState(0);
  const GetAllprojects = (p = {}) => {
    const newfilters = {
      ...Filters,
      ...p,
      skills: p.skills
        ? p.skills.map((item) => item.id).toString()
        : Filters.skills.map((item) => item.id).toString(),
      technology: p.technology
        ? p.technology.map((item) => item.id).toString()
        : Filters.technology.map((item) => item.id).toString(),
      country: p.country
        ? p.country.map((item) => item.id).toString()
        : Filters.country.map((item) => item.id).toString(),
    };
    loader(true);
    setJobLoading(true)
    ApiClient.get(`posted/projects`, newfilters).then((result) => {
      if (result.success) {
        const data = result.data;
        setTotal(result.total);
        setProjectsData([...result.data]);
        setTimeout(() => {
          loader(false);
          setJobLoading(false)
        }, 200);
      }

    });
  };

  const HandleShortlist = (e, id) => {
    loader(true);
    e.preventDefault();
    const payload = { projectId: id, type: "project" };
    ApiClient.post(`add_sortlist`, payload).then((res) => {
      if (res.success) {
        GetAllprojects();
        document.getElementById("closeJobDetails").click();
      }
      loader(false);
    });
  };

  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    // console.log("Good morning");
  } else if (curHr < 18) {
    // console.log("Good afternoon");
  } else {
    // console.log("Good evening");
  }

  var today = new Date();
  var curHr = today.getHours();
  var text = "";
  if (curHr < 12) {
    text = "Good Morning";
  } else if (curHr < 18) {
    text = "Good Afternoon";
  } else {
    text = "Good Evening";
  }


  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var d = new Date();
  var dayName = days[d.getDay()];

  // Sample profile completion logic
  const requiredFields = ["name", "email", "bio", "profilePicture"];

  // Calculate the completion percentage
  const calculateCompletionPercentage = () => {
    const completedFields = requiredFields.filter();
    // field => Check if the field is completed
    const percentage = (completedFields.length / requiredFields.length) * 100;
    return percentage;
  };

  //  For Pagination We need this Function
  const pageChange = (e) => {
    setFilters({ ...Filters, page: e });
    GetAllprojects({ page: e });
  };

  useEffect(() => {
    GetAllprojects();
  }, []);

  const HandleSearchFilter = (value) => {
    setFilters({ ...Filters, search: value });
    setTimeout(() => {
      GetAllprojects({ search: value });
    }, 2000);
  };
  const HandleSearchSubmit = (e, value = Filters.search) => {
    e.preventDefault();
    Navigate.push(`/jobsearch?search=${value}`);
    GetAllprojects({ search: value });
  };
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const HandleRemoveShortlist = (e, id) => {
    loader(true);
    e.preventDefault();
    const payload = { projectId: id };
    ApiClient.delete(`sortlist`, payload).then((res) => {
      if (res.success) {
        GetAllprojects();
        document.getElementById("closeJobDetails").click();
      }
      loader(false);
    });
  };
  const HandleNumberofPurposals = (data) => {
    if (data < 50) {
      if (data < 5) {
        return `Less than 5`;
      }
      const quatioent = data / 5;
      const main = Math.trunc(quatioent);
      const startNumber = main * 5;
      const EndNumber = startNumber + 5;
      return `${startNumber} to ${EndNumber} Porposal`;
    } else {
      return "More than 50 ";
    }
  };
  return (
    <>
      <Header />
      <div className="container vertual_container mt-4 mb-5">
        <div className="row">
          <div className="col-lg-9 col-md-8 mb-4">
            <div className="top_box main_headindd mb-4 d-flex justify-content-between ">
              <div>
                <h4 className="daycls mt-3">
                  {dayName}, {methodModel.convertDate(currentDate)}
                </h4>
                <h6 className="messagcls">
                  {text}
                  <span>, {user.fullName}</span>
                </h6>
              </div>
              <div>
                <img
                  src="https://preply.com/wp-content/uploads/2021/08/Grman-language-certifications-featured-image.jpg"
                  className="img_rabot"
                />
              </div>
            </div>

            <div className="sticky_search mb-3">
              <form
                className="job_search"
                onSubmit={(e) => HandleSearchSubmit(e)}
              >
                <div className="input-group">
                  <input
                    type="search"
                    placeholder="Search for Job"
                    value={Filters.search}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setFilters({ ...Filters, search: "" });
                        HandleSearchSubmit(e, "");
                      } else {
                        setFilters({ ...Filters, search: e.target.value });
                      }
                    }}
                    id="form1"
                    className="form-control d-flex-inline var_search pl-4"
                  />
                  <div className="input-group-append">
                    <button
                      type="submit"
                      className="btn btn-primary pl-3 pr-3 serchbtn_cls"
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>

              {/* <button  className='btn btn-primary pl-4 pr-4' data-toggle="modal" data-target="#exampleModalLong">Add Product</button> */}
            </div>
            <div className="maindiv_boder">
              <div className="tab_div pl-md-4 pt-md-4 pt-3 pl-3 pb-0 border-bottom">
                <h3 className="mb-md-4 mb-3 Jobs_Jobs">Jobs you might like</h3>
              </div>

              {!JobLoading && ProjectsData?.map((item, index) => (
                <div className="col-md-12 border-bottom mt-3 pt-3 mb-3 pb-3 jobsearch_box">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex">
                      <div className="product_details">
                        <p className="mb-0">
                          <b
                            className="text-primary font-weight-bold text-capitalize pointer"
                            onClick={(e) => setViewProjectData(item)}
                          >
                            <a
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              {item?.name || item?.title}
                            </a>
                          </b>
                        </p>
                        <p className="gay_title mb-1">
                          <span className="hourly_rate">
                            Hourly - Approx {" "}{item.hourlyratestart}{" "}
                            USD
                            {/* - {item.hourlyrateEnd} USD */}
                          </span>{" "}
                          - posted {GetPostedTime(item.createdAt)}
                        </p>

                        <p
                          className=" description_cls  mb-0"
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        >
                          {/* {item.description} */}
                        </p>
                        <div className="badges_project"></div>
                        {/* end */}
                        <div className="badges_project">
                          {item.skills &&
                            item.skills.map((item, index) => (
                              <span>{item.name}</span>
                            ))}
                        </div>

                        <p className=" mt-2 mb-2">
                          <span className="Proposals_cls">Proposals: </span>
                          {methodModel.GetNumberRange(item.totalJobApply)}
                        </p>

                        <div className="">
                          {item?.addedByDetail?.isAchpaymentVerified ? (
                            <i
                              className="fa fa-check-circle mr-1"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            // <i
                            //   className="fa fa-times-circle"
                            //   aria-hidden="true"
                            // ></i>
                            <></>
                          )}
                          {item?.addedByDetail?.isAchpaymentVerified ? <span className="ml-1">Payment verified</span> : ""}
                          <span className="stardi">
                            <div className="mt-2">
                              {methodModel.generateStarRating(
                                Number(item?.addedByDetail?.totalRating)
                              )}
                            </div>
                          </span>
                          <div className="my-2">
                            Approx{" "} {item.hourlyratestart}{" "} USD
                            {/* - {item.hourlyrateEnd} USD */}
                            {" "}
                            <span className="gray_cls">/hrs</span>
                            {item.addedByDetail &&
                              item.addedByDetail.country != "" ? (
                              <span className="gray_cls ml-2">
                                <span className="material-icons location">
                                  location_on
                                </span>{" "}
                                {item.addedByDetail.country}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post_btn d-flex">
                      <button className="btn btn-outline-primary header_like mr-2">
                        <span className="material-icons">
                          thumb_down_off_alt
                        </span>
                      </button>

                      <a
                        title={item.isShort ? "unfavorite" : "favorite"}
                        className="fav_icon_revealeant "
                        onClick={(e) => {
                          item.isShort
                            ? HandleRemoveShortlist(e, item.id)
                            : HandleShortlist(e, item.id);
                        }}
                      >
                        <span className={`material-icons`}>
                          {item.isShort ? "favorite" : "favorite_border"}
                        </span>
                      </a>
                      {/* </button> */}
                    </div>
                  </div>
                </div>
              ))}
              {JobLoading ? <div class="loading" data-loading-text="Loading..."></div> : null}

              {/* {ProjectsData.length == 0 ? (
                <div className="text-danger text-center mt-5">No Data</div>
              ) : null} */}
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="border_userDate p-3 text-center mb-4 sidebox">
              <img
                src={`${user.image == ""
                  ? "/assets/img/person.jpg"
                  : methodModel.userImg(user.image)
                  } `}
                className="img_person"
              />
              <p className="mt-3 mb-1 text-capitalize">
                <span className="profilename ">{user.fullName}</span>
              </p>
            </div>

            <Link to="/profile" className="link_cls">
              {" "}
              <div className="border_userDate bg_blue_port pt-3 pb-2 pl-3 pr-3 mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="">Go to Profile</h6>
                  <span
                    className="material-icons"
                    onClick={(e) => Navigate.push("/profile")}
                    style={{ cursor: "pointer" }}
                  >
                    arrow_forward
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mr-2 parah-size ">
                    Adding a project may increase your changes of earning by 8
                    times.
                  </p>
                  <span className="material-icons increase">add_task</span>
                </div>
              </div>
            </Link>

            <Link to="/perposallist" className="link_cls">
              <div className="border_userDate  pt-3 pb-2 pl-3 pr-3 mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="">My Jobs</h6>
                  <span className="material-icons">arrow_forward</span>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mr-2 parah-size">
                    View your active contracts, timesheets,and available
                    earnings.
                  </p>
                  <span className="material-icons increase">
                    business_center
                  </span>
                </div>
              </div>
            </Link>

            <Link to="/perposallist" className="link_cls">
              <div className="border_userDate  pt-3 pb-2 pl-3 pr-3 mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="">My Proposals</h6>
                  <span
                    onClick={(e) => Navigate.push("/invites")}
                    style={{ cursor: "pointer" }}
                    className="material-icons"
                  >
                    arrow_forward
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mr-2 parah-size ">
                    Review you perposals, offers, referrals, and client
                    invitations.
                  </p>

                  <span className="material-icons increase">
                    import_contacts
                  </span>
                </div>
              </div>
            </Link>

            <div className="accordian_cls main_clsacc">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div
                    className="card-header cordian_header d-flex justify-content-between"
                    id="headingOne"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left colaps_cls pl-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Connects
                      </button>
                    </h2>
                    <i
                      className="fa fa-caret-down mt-2"
                      aria-hidden="true"
                      data-toggle="collapse"
                      id="closecanvas"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    ></i>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. <code>.show</code> class.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header cordian_header d-flex justify-content-between"
                    id="headingTwo"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed colaps_cls pl-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Preferences
                      </button>
                    </h2>
                    <i
                      className="fa fa-caret-down mt-2"
                      aria-hidden="true"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    ></i>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header cordian_header d-flex justify-content-between"
                    id="headingThree"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed colaps_cls pl-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Proposals
                      </button>
                    </h2>
                    <i
                      className="fa fa-caret-down mt-2"
                      aria-hidden="true"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    ></i>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <h3 className="closer_heding_cls">My Proposals</h3>
                      Looking for work? Browse jobs and get started on a
                      proposal.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_userDate p-3 text-left mb-4 sidebox mt-4">
              <Link to="/faq" className="link_cls">
                <p className="underline_cls">
                  FAQs <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </p>
              </Link>
              <Link to="/blogs" className="link_cls">
                <p className="underline_cls mb-0">
                  Blogs <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </p>
              </Link>
            </div>
          </div>
        </div>
        {total > Filters.count ? (
          <Pagination
            activePage={Filters.page}
            // itemClassPrev="back_page"
            itemClassNext="next_page"
            itemsCountPerPage={Filters.count}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={pageChange}
          />
        ) : null}
      </div>

      {/* offcanvas */}
      <div
        className="offcanvas offcanvas-end space_canvas"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <i
            className='fa fa-angle-left newback data-bs-dismiss="offcanvas"
            aria-label="Close"'
            type="button"
            id="closeJobDetails"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></i>
        </div>

        <div className="offcanvas-body">
          <div className="card">
            <div className="row">
              <div className="col-md-8 pr-0">
                <div className="p-3">
                  <h3>{ViewProjectData?.name || ViewProjectData?.title}</h3>
                  <p className="text-primary mt-md-3 ">
                    {ViewProjectData.skillType?.map((items,ind)=>{
                      return <span className="badges text-capitalize">{items?.name || "--"}</span>
                    })}
                  </p>

                  <p className="mb-md-2 mb-0 ">
                    Posted {GetPostedTime(ViewProjectData.createdAt)}
                  </p>
                </div>
                <div className="col-12 px-md-0 pl-0">
                  <div>
                    {" "}
                    <hr />
                  </div>
                </div>
                <p
                  className="p-3 fixfont"
                  dangerouslySetInnerHTML={{
                    __html: ViewProjectData?.description,
                  }}
                ></p>
                <div className="col-12 px-md-0 pl-0">
                  <div>
                    {" "}
                    <hr />{" "}
                  </div>
                </div>
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="price_pro d-flex">
                      <span className="material-icons price_local mt-1">
                        local_offer
                      </span>
                      <div className="ml-2">
                        <b>
                          {" "}
                          Approx {" "}{ViewProjectData.hourlyratestart} USD{" "}/hrs
                          {/* -{ViewProjectData.hourlyrateEnd} USD */}
                        </b>
                        <p>Budget</p>
                      </div>
                    </div>
                    <div className="price_pro d-flex">
                      <span className="material-icons price_locals">
                        manage_accounts
                      </span>
                      <div className="ml-2">
                        <b>Intermediate</b>
                        <p>
                          I am looking for a mix of <br /> experience and value
                        </p>
                      </div>
                    </div>
                  </div>
                  {ViewProjectData?.hardSkill_detail?.length > 0 ?
                    <div className="badges_project mb-2">
                      <p className="text-bold d-inline skills_txt mr-1">
                        Hard Skills:
                      </p>{" "}
                      {ViewProjectData?.hardSkill_detail?.map((item, index) => (
                        <span className="badges text-capitalize" key={index}>{item?.name}</span>
                      ))}
                    </div>
                    : null}
                  {ViewProjectData?.softSkill_detail?.length > 0 ?
                    <div className="badges_project mb-2">
                      <p className="text-bold d-inline skills_txt mr-1">
                        Soft Skills:
                      </p>{" "}
                      {ViewProjectData?.softSkill_detail?.map((item, index) => (
                        <span className="badges text-capitalize" key={index}>{item?.name}</span>
                      ))}
                    </div>
                    : null}
                </div>
              </div>
              <div className="col-md-4  pl-0 border-left">
                <div className="p-md-3 p-4 text-center">
                  <span>
                    {" "}
                    <button
                      className="btn btn-primary mb-3  appybtn"
                      disabled={
                        ViewProjectData.isJobApplied == true ? true : false
                      }
                      onClick={(e) => {
                        Navigate.push(
                          `/perposal/${ViewProjectData._id}?apply=job`
                        );
                        document.getElementById("closeJobDetails").click();
                      }}
                    >
                      {!ViewProjectData.isJobApplied ? "Apply Now" : "Applied"}
                    </button>
                  </span>
                  <span>
                    <button
                      title={
                        ViewProjectData.isShort ? "unfavorite" : "favorite"
                      }
                      className="btn btn-outline-primary mb-2 appybtn"
                      onClick={(e) => {
                        ViewProjectData.isShort
                          ? HandleRemoveShortlist(e, ViewProjectData.id)
                          : HandleShortlist(e, ViewProjectData.id);
                      }}
                    >
                      {" "}
                      <span className={`material-icons`}>
                        {ViewProjectData.isShort
                          ? "favorite"
                          : "favorite_border"}
                      </span>
                      <span className=" ">Saved Job</span>
                    </button>
                  </span>
                  {/* <p>Send a proposal for:12 Connects</p> */}
                  {/* <p>Available Connects: 60</p> */}
                </div>
                <div className="col-12 px-md-0 pr-0">
                  <div>
                    {" "}
                    <hr />
                  </div>
                </div>
                <div className="p-md-3 p-4">
                  <h6 className="mb-3">
                    <b>About the client</b>
                  </h6>
                  <h6 className="mb-3">
                    <b>
                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.companyName}
                    </b>
                  </h6>
                  <br />
                  <p className="mb-3 mt-2">
                    <b>
                      {/* {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.city} */}

                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.country}
                    </b>
                  </p>
                  {/* <p className="mb-3">
                    {ViewProjectData.addedByDetail &&
                      ViewProjectData.addedByDetail.timeZone &&
                      ViewProjectData.addedByDetail.timeZone.label}
                  </p> */}
                  <b>8 Jobs Posted</b>
                  <p>25% hire rate, 4 open jobs</p>
                  <b>
                    $
                    {ViewProjectData.addedByDetail &&
                      ViewProjectData.addedByDetail.hourlyRate}{" "}
                    /hr avg hourly rate paid
                  </b>
                  <p>56 hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Project
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Project Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Technology</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Skills</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Start Date</label>
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">End Date</label>
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Description</label>
                    <textarea type="text" className="form-control"></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="mb-0">Upload Documents</label>
                  <div className="profile_btn_portfolio ">
                    <label className="add_portfolio edit ml-3">
                      <input
                        id="bannerImage"
                        type="file"
                        multiple=""
                        className="d-none"
                      />
                      <span className="add_portfolio">
                        <i className="material-icons add_port">add</i>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="col-md-12 text-right">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
