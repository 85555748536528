import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import Sidebar from "../sidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import moment from "moment";
import { ConnectSocket, SocketURL } from "../SocketNetwork/ConnectSocket";
import axios from "axios";
import { MessageCount } from "../../../actions/search";
import { PlatformfeeAction } from "../../../actions/Platfromfee";
import environment from "../../../environment";
import { toast } from "react-toastify";
const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  isOpen1,
  searchChange,
  clear,
  Logout,
  setrole,
}) => {
  const MessageCounter = useSelector((state) => state.MessageCount);
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const HandleSignupEvent = (data) => {
    Navigate.push(`/signup?role=${data}`);
  };

  const HandlePushUser = (url) => {
    if (user.loggedIn) {
      Navigate.push(`${url}`);
    } else {
      Navigate.push("/login");
    }
  };

  //  For Handling the Virtual  zoho contract Flow
  const HandleVirtualZohoPusher = (url) => {

    // if (user.loggedIn) {
    //   Navigate.push(`${url}`);
    // } else {
    //   Navigate.push("/login");
    // }

    if (user && user?.id) {
      ApiClient.get(`profile?id=${user.id}`).then((res1) => {
        if (user?.role?.id == environment.VirtualId) {
          if (
            (!res1?.data?.zohoSignIn || res1?.data?.zohoSignIn == false) &&
            res1?.data?.isVerifiedDocument === "accepted"
          ) {
            loader(true);
            ApiClient.post(`virtual/zoho/sign-doc`).then((res) => {
              if (res.success) {
                // alert("success zoho sign")
                // return
                // window.open(`${res.data && res.data?.sign_url}`, "_parent");
                // Step 1: Create the button element
                const button = document.createElement('button');

                // Step 2: Set button attributes and add event listener
                button.id = 'openLinkButton2';
                button.innerHTML = 'Open Link'; // This is the button text (optional)

                button.addEventListener('click', function () {
                  const url = res.data && res.data.sign_url;
                  window.open(url, '_blank'); // Opens the URL in a new tab
                });

                // Step 3: Append the button to the body (necessary for the click event to work in some cases)
                document.body.appendChild(button);

                // Programmatically trigger the click
                button.click();

                // Optional: Remove the button after the click to clean up the DOM
                document.body.removeChild(button);
              }
              loader(false);
            });
          } else {
            Navigate.push(`${url}`);
          }
        } else {
          Navigate.push(`${url}`);
        }
      });
    } else {
      Navigate.push("/login");
    }
  };

  const [NotificationTotal, setNotificationTotal] = useState(0);
  const [Notifications, setNotifications] = useState([]);
  const [NotificationLoader, setNotificationLoader] = useState(false);
  const GetAllNotification = () => {
    setNotificationLoader(true);
    // For Zoho W8 form if need to show in notication
    // ApiClient.post(`w8form-sign`).then(response => {
    //   if (response.data) {
    //     setNotificationTotal(1);
    //     setNotifications([{ link: response?.data || "", title: "Please fill the W8 form...Click here", addedBy_details: { image: "" }, type: "zoho" }]);
    //   } else {
    //     ApiClient.get(`notifications?page=1&count=5&status=unread`).then((res) => {
    //       if (res.success) {
    //         setNotificationTotal(res.total);
    //         setNotifications(res.data);
    //       }
    //     });
    //   }
    //   setNotificationLoader(false);
    //   loader(false);
    // })
    ApiClient.get(`notifications?page=1&count=5&status=unread`).then((res) => {
      if (res.success) {
        setNotificationTotal(res.total);
        setNotifications(res.data);
      }
      setNotificationLoader(false);
      loader(false);
    });
  };
  useEffect(() => {
    if (user.loggedIn) {
      GetAllNotification();
    }
  }, []);
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const HandleReadNotification = (id) => {
    loader(true);
    ApiClient.put(`notification?id=${id}`, { notificationStatus: "read" }).then(
      (res) => {
        GetAllNotification();
        loader(false);
      }
    );
  };

  useEffect(() => {
    if (
      user.role &&
      user.role.id == environment.EmployerId &&
      user.isVerifiedDocument != "accepted" &&
      pathname != "/virtual"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        Navigate.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.VirtualId &&
      user.isVerifiedDocument != "accepted"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        Navigate.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.VirtualId &&
      user.isVerifiedDocument == "rejected"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        Navigate.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.VirtualId &&
      user.isVerifiedDocument == "pending"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        Navigate.push("/profile");
      }
    }
  }, []);
  const [InviteRejectReason, setInviteRejectReason] = useState("");
  const HandePushNotification = (e, item) => {
    e.preventDefault();
    // For Zoho W8 form if need to show in notication
    // if(item?.type == 'zoho'){
    //   window.open(item?.link,"_self")
    //   return
    // }
    if (item.type == "applyjob") {
      Navigate.push(`/details/${item.applyJobId}`);
    } else {
      if (item.inviteId_details && item.inviteId_details.status == "rejected") {
        setInviteRejectReason(
          item.inviteId_details && item.inviteId_details.reason
        );
        document.getElementById("openreject").click();
      } else {
        if (
          item.type == "find_notice" ||
          item.type == "notice" ||
          item.type == "noticeRejected" ||
          item.type == "noticeAccepted"
        ) {
          Navigate.push("/notice");
        } else if (
          item?.inviteId_details &&
          item.inviteId_details?._id != undefined
        ) {
          Navigate.push(
            `/proposaldetail/${item?.inviteId_details && item.inviteId_details?._id
            }`
          );
        }
      }
      if (item.type == "billingSchedule") {
        HandleReadNotification(item._id);
        return Navigate.push("/timeclock/requests");
      }
      if (
        item.type == "workSchedule" ||
        item.type == "workScheduleAccepted" ||
        item.type == "workScheduleRejected" ||
        item.type == "billingSchedule" ||
        item.type == "timeZone"
      ) {
        Navigate.push(`/offer/${item.contractId}`);
      }
      if (
        item.type == "contract" ||
        item.type == "contractAccepted" ||
        item.type == "contractRejected" ||
        item?.type == "rating"
      ) {
        Navigate.push(`/offer/${item.contractId}`);
      }
    }
    HandleReadNotification(item._id);
  };

  //  All the Coding Here for Connect Socket
  useEffect(() => {
    ConnectSocket.on("connect", (data) => { });
  }, []);

  useEffect(() => {
    ConnectSocket.emit(`user-online`, { user_id: user.id });
    return () => {
      ConnectSocket.emit(`user-offline`, { user_id: user.id });
    };
  }, []);

  const GetAllMessageUnreadCount = () => {
    axios
      .get(`${SocketURL}chat/user/unread-count/all?user_id=${user.id}`)
      .then((newres) => {
        if (newres) {
          const res = newres.data && newres.data.data;
          dispatch(MessageCount(res.unread_count ? res.unread_count : 0));
        }
      })
      .catch((err) => {
        // toast.clear()
      });
  };
  useEffect(() => {
    if (user.loggedIn) {
      GetAllMessageUnreadCount();
    }
  }, []);

  useEffect(() => {
    ConnectSocket.on(`unread-noti-count`, (data) => {
      const newdata = data.data;
      if (newdata.user_id == user.id && localStorage.getItem("token")) {
        setNotificationTotal(newdata.unread_count);
      }
    });

  }, []);

  const HandleRecruiterController = () => {
    if (
      user.role &&
      user.role.id == environment.RecruiterId &&
      user.isVerifiedDocument != "accepted"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        Navigate.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.RecruiterId &&
      user.isVerifiedDocument == "rejected"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        // toast.error("Your account is Inactive or blocked by Admin.Please upload updated Documents");
        Navigate.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.RecruiterId &&
      user.isVerifiedDocument == "pending"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        // toast.success("Your Account is under progress.Admin will Approve it Shortly ")
        Navigate.push("/profile");
      }
    }
  };

  useEffect(() => {
    HandleRecruiterController();
    dispatch(PlatformfeeAction("12"));
  }, []);

  //  For Handling the Recruiter  Sign Flow
  const HandleRecruiterPusher = (url) => {
    if (user && user?.id) {
      ApiClient.get(`profile?id=${user.id}`).then((res1) => {
        if (user?.role?.id == environment.RecruiterId) {
          if (
            (!res1?.data?.zohoSignIn || res1?.data?.zohoSignIn == false) &&
            res1?.data?.isVerifiedDocument === "accepted"
          ) {
            loader(true);
            ApiClient.post(`recruiter/zoho/sign-doc`).then((res) => {
              if (res.success) {
                window.open(`${res.data && res.data?.sign_url}`, "_parent");
              }
              loader(false);
            });
          } else {
            Navigate.push(`${url}`);
          }
        } else {
          Navigate.push(`${url}`);
        }
      });
    } else {
      if (url == "/ReferList") {
        //  Here we will put the static referal data
        Navigate.push("/referal/info");
      } else {
        Navigate.push("/login");
      }
    }
  };



  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        id="openreject1"
        data-toggle="modal"
        data-target="#exampleModalCenter1"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Reason of Rejection
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{InviteRejectReason}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <nav
        component="header"
        className="  navbar navbar-expand-lg navbar-light bg-light"
      >
        <Link to="/">
          <img
            className="myvirtualpalLogo151Icon"
            alt=""
            width={"100%"}
            height={`100%`}
            loading="lazy"
            src="/assets/img/myvirtualpallogo15-1@2x.png"
          />
        </Link>
        <button
          className="navbar-toggler ml-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="desk-show d-none ms-2">
          {user.loggedIn ? (
            <li className="noti_fication">
              <div className="dropdown">
                <a
                  className="dropdown-toggle position-relative remove_after"
                  onClick={(e) => GetAllNotification()}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="material-icons">notifications_none</span>
                  {NotificationTotal != 0 && user.loggedIn ? (
                    <span className="red_dot">{NotificationTotal}</span>
                  ) : null}
                </a>
                <ul className="dropdown-menu noti_dropdown pb-0 pt-0">
                  {NotificationLoader ? (
                    <div className="text-center">
                      <i className="fa fa-loader">Loading.....</i>
                    </div>
                  ) : (
                    Notifications.map((item, index) => (
                      <li className="border-bottom">
                        <a
                          className="dropdown-item"
                          onClick={(e) => HandePushNotification(e, item)}
                        >
                          <div className="d-flex icon_bell">
                            <div className="img_msg">
                              <img
                                src={methodModel.userImg(
                                  item.addedBy_details &&
                                  item.addedBy_details.image
                                )}
                                className="small_user"
                                alt=""
                              />
                            </div>
                            <p className="ml-3 noti_data">
                              {item.title}
                              <br />
                              <small>{GetPostedTime(item.createdAt)}</small>
                            </p>
                          </div>
                        </a>
                      </li>
                    ))
                  )}
                  {!NotificationLoader && Notifications.length == 0 ? (
                    <div className="text-center text-danger">
                      <p className="pt-3">No Notification Here</p>
                    </div>
                  ) : null}
                  <li>
                    {NotificationTotal > 5 ? (
                      <Link
                        to="/notifications"
                        className="dropdown-item pb-3 see_All"
                      >
                        See all notification
                      </Link>
                    ) : null}
                  </li>
                </ul>
              </div>
            </li>
          ) : null}
          {user.loggedIn ? (
            <>
              <div className="dropdown headerleft">
                <a
                  data-toggle="dropdown"
                  className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center image-shape"
                >
                  <img
                    alt="image"
                    src={methodModel.userImg(user.image)}
                    width={40}
                    height={40}
                    className="rounded-circle mr-1"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover prodiv_cl">
                  <div className="text-center">
                    <img
                      alt="image"
                      src={methodModel.userImg(user.image)}
                      width={50}
                      height={50}
                      className="rounded-circle drop_innerimg mb-2"
                    />
                    <div className="ml-1 ">
                      <b className="mt-2">{user.fullName}</b>
                      <p className="dropgray mb-0 text-capitalize mt-1 w-100">
                        {user.role?.name}
                      </p>
                    </div>
                  </div>

                  <Link to="/profile" className="dropdown-item has-icon">
                    <i className="far fa-user mr-1" /> Profile
                  </Link>
                  <Link to="/change" className="dropdown-item has-icon ">
                    <i className="fa fa-cog mr-1" aria-hidden="true"></i> Change
                    Password
                  </Link>
                  {user.role && user.role.id == environment.EmployerId ? (
                    <Link to="/time" className="dropdown-item has-icon ">
                      <i className="fa fa-wrench mr-1" aria-hidden="true"></i>
                      Policy Settings
                    </Link>
                  ) : null}
                  {user.role && user.role.id == environment.EmployerId ? (
                    <Link to="/table" className="dropdown-item has-icon ">
                      <i className="fa fa-clock mr-1" aria-hidden="true"></i>
                      Time Schedule
                    </Link>
                  ) : null}
                  {/* {  user.role&&user.role.id==environment.EmployerId?user.accountManager_created==true||user.directManager_created==true||user.staffIntreset==true?<Link to="/mystaff" className="dropdown-item has-icon ">
    <i className="fa fa-users mr-1" aria-hidden="true"></i> Staff
  </Link>:null:null} */}
                  {user.role && user.role.id == environment.EmployerId ? (
                    <Link to="/mystaff" className="dropdown-item has-icon ">
                      <i className="fa fa-users mr-1" aria-hidden="true"></i>{" "}
                      Staff
                    </Link>
                  ) : null}

                  {(user.role && user.role.id == environment.EmployerId) ||
                    user.role.id == environment.VirtualId ? (
                    <Link to="/notice" className="dropdown-item has-icon ">
                      <i className="fa fa-book mr-1" aria-hidden="true"></i>{" "}
                      Notice
                    </Link>
                  ) : null}

                  <Link to="/savedcard" className="dropdown-item has-icon ">
                    <i className="fa fa-credit-card mr-2"></i>Saved Cards
                  </Link>
                  <Link to="/banks" className="dropdown-item has-icon ">
                    <i className="fa fa-university mr-2"></i>ACH Payment
                  </Link>
                  <a
                    id="WantLogout"
                    onClick={() => {
                      Logout();
                      setNotificationTotal(0);
                    }}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-sign-out-alt mr-1" /> Logout
                  </a>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="header navbar-nav justify-content-center align-items-center">
            {user?.role && user?.role?.id === environment?.VirtualId ? (
              <li
                className="nav-item nav-link heder_drop"
                onClick={(e) => HandlePushUser("/virtual/dashboard")}
              >
                Dashboard
              </li>
            ) : null}
            {user.role && user.role.id != environment.RecruiterId ? (
              <>
                <div className="dropdown">
                  <button
                    className=" dropdown-toggle heder_drop"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Find{" "}
                    {user.role && user.role.id == environment.VirtualId
                      ? "Work"
                      : "Virtual Assistant"}
                  </button>
                  <div className="dropdown-menu header_menu relateit">
                    {user.role && user.role.id == environment.VirtualId ? (
                      <a
                        className="dropdown-item"
                        onClick={(e) => HandlePushUser("/projectslist")}
                      >
                        Find work
                      </a>
                    ) : (
                      <a
                        className="dropdown-item"
                        onClick={(e) => HandlePushUser("/virtual")}
                      >
                        Find Virtual Assistant
                      </a>
                    )}
                    {user.role && user.role.id == environment.VirtualId ? (
                      <a
                        className="dropdown-item"
                        onClick={(e) => HandlePushUser("/savedlist")}
                      >
                        Saved Jobs
                      </a>
                    ) : (
                      <a
                        className="dropdown-item"
                        onClick={(e) => HandlePushUser("/shortlist")}
                      >
                        Shortlisted Virtual Assistant
                      </a>
                    )}
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => HandlePushUser("/perposallist")}
                    >
                      Proposals
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={(e) => HandlePushUser("/profile")}
                    >
                      Profile
                    </a>
                  </div>
                </div>

                <div className="dropdown">
                  <button
                    className=" dropdown-toggle heder_drop"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    My Jobs
                  </button>
                  <div className="dropdown-menu header_menu relateit">
                    {user.role &&
                      user.role.id == environment.VirtualId ? null : (
                      <>
                        <a
                          className="dropdown-item"
                          onClick={(e) => HandlePushUser("/project")}
                        >
                          My Jobs
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={(e) => HandlePushUser("/addeditproject")}
                        >
                          Post a Job
                        </a>
                      </>
                    )}
                    <a
                      className="dropdown-item"
                      onClick={(e) => HandlePushUser("/contracts")}
                    >
                      All Contracts
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={(e) =>
                        HandlePushUser(
                          `${user.role && user.role.id == environment.EmployerId
                            ? "/work-diary"
                            : "/trans/virtual"
                          }`
                        )
                      }
                    >
                      Work Diary
                    </a>
                  </div>
                </div>

                <div className="dropdown">
                  <button
                    className=" dropdown-toggle heder_drop"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Reports
                  </button>
                  <div className="dropdown-menu header_menu relateit">
                    <a
                      className="dropdown-item"
                      onClick={(e) =>
                        HandlePushUser(
                          `${user.role && user.role.id == environment.EmployerId
                            ? "/report"
                            : user.role.id == environment.VirtualId
                              ? "/virtual/report"
                              : "/recruiter/report"
                          }`
                        )
                      }
                    >
                      My Reports
                    </a>
                  </div>
                </div>
                {user?.role && user?.role?.id === environment?.VirtualId ? (
                  <>
                    {" "}
                    <li
                      className="nav-item nav-link heder_drop"
                      onClick={(e) => HandleVirtualZohoPusher("/sharereferralcode/virtual")}
                    >
                      Refer Virtual Assistant
                    </li>
                    <li
                      className="nav-item nav-link heder_drop"
                      onClick={(e) => HandlePushUser("/ReferList")}
                    >
                      Referrals
                    </li>
                  </>
                ) : null}

                {/* Its for refer employer */}
                {user.role && user.role.id == environment.EmployerId ? (
                  <>
                    {/* <li className="nav-item nav-link heder_drop" onClick={e => HandleRecruiterPusher("/sharereferralcode/virtual")}>Refer Virtual Assistant</li> */}
                    <li
                      className="nav-item nav-link heder_drop"
                      onClick={(e) =>
                        Navigate.push("/sharereferralcode/employer")
                      }
                    >
                      Refer Employer
                    </li>
                    <li
                      className="nav-item nav-link heder_drop"
                      onClick={(e) => Navigate.push("/ReferList")}
                    >
                      Referrals
                    </li>
                  </>
                ) : null}
                <li
                  className="nav-item nav-link heder_drop"
                  onClick={(e) => HandlePushUser("/chat")}
                >
                  Messages
                  <span
                    className={`${MessageCounter.data == 0 ? "" : "messageUnread"
                      }`}
                  >
                    {MessageCounter.data == 0 ||
                      typeof MessageCounter.data == "object"
                      ? ""
                      : MessageCounter.data}
                  </span>
                </li>
              </>
            ) : (
              <>
                {/* Its For Recruiter */}
                {user.loggedIn ? (
                  <li
                    className="nav-item nav-link heder_drop"
                    onClick={(e) =>
                      HandleRecruiterPusher("/recruiter/dashboard")
                    }
                  >
                    Dashboard
                  </li>
                ) : null}
                {user.loggedIn ? (
                  <li
                    className="nav-item nav-link heder_drop"
                    onClick={(e) =>
                      HandleRecruiterPusher("/sharereferralcode/virtual")
                    }
                  >
                    Refer Virtual Assistant
                  </li>
                ) : null}
                {user.loggedIn ? (
                  <li
                    className="nav-item nav-link heder_drop"
                    onClick={(e) =>
                      HandleRecruiterPusher("/sharereferralcode/employer")
                    }
                  >
                    Refer Employer
                  </li>
                ) : null}
                <li
                  className="nav-item nav-link heder_drop"
                  onClick={(e) => HandleRecruiterPusher("/ReferList")}
                >
                  Referrals
                </li>
              </>
            )}
            {/* <div  className="dropdown">
  <button  className="btn btn-secondary dropdown-toggle heder_drop" type="button" data-toggle="dropdown" aria-expanded="false">
  Reports
  </button>
  <div  className="dropdown-menu header_menu relateit">
    <a  className="dropdown-item" onClick={e=>HandlePushUser(`${user.role&&user.role.id==environment.EmployerId?"/report":user.role.id==environment.VirtualId?"/virtual/report":"/recruiter/report"}`)}>My Reports</a>
  </div>
</div> */}
            <li
              onClick={(e) => HandlePushUser("/blogs")}
              className="nav-item nav-link heder_drop"
            >
              Blogs
            </li>
            <Link to="/faq">
              <li className="nav-item nav-link heder_drop">FAQs</li>
            </Link>
            {user?.role?.id && user?.role?.id === environment?.EmployerId &&
              <Link to="/timeclock/requests">
                <li className="nav-item nav-link heder_drop">Time Clock Requests</li>
              </Link>
            }

          </ul>
          <div className="h_drop  ">
            {!user.loggedIn ? (
              <>
                <li
                  className="nav-item nav-link headerChild mr-0 login-btn btn_mains"
                  onClick={(e) => Navigate.push("/login")}
                >
                  Login
                </li>
                <li className="licls signup-btn">
                  <div className="dropdown">
                    <button
                      className="nav-item nav-link headerItem  btn_mains"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sign Up{" "}
                      <i
                        className="fa fa-angle-down ml-1"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div
                      className="dropdown-menu  rightDrop_down shadow"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => HandleSignupEvent("employer")}
                      >
                        Employer
                      </a>
                      <a
                        className="dropdown-item mt-2"
                        href="#"
                        onClick={(e) => HandleSignupEvent("virtual")}
                      >
                        Virtual Assistant
                      </a>
                      <a
                        className="dropdown-item mt-2"
                        href="#"
                        onClick={(e) => HandleSignupEvent("recruiter")}
                      >
                        Recruiter
                      </a>
                    </div>
                  </div>
                </li>
              </>
            ) : null}
          </div>

          {isOpen1 ? (
            <div className="w-100 mobi-dropdown">
              <Sidebar />
            </div>
          ) : (
            <></>
          )}
        </div>
        {user.loggedIn ? (
          <li className="noti_fication notify-none mt-2">
            <div className="dropdown">
              <a
                className="dropdown-toggle position-relative remove_after"
                onClick={(e) => GetAllNotification()}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="material-icons">notifications_none</span>
                {NotificationTotal != 0 && user.loggedIn ? (
                  <span className="red_dot">{NotificationTotal}</span>
                ) : null}
              </a>
              <ul className="dropdown-menu noti_dropdown pb-0 pt-0">
                {NotificationLoader ? (
                  <div className="text-center">
                    <i className="fa fa-loader">Loading.....</i>
                  </div>
                ) : (
                  Notifications.map((item, index) => (
                    <li className="border-bottom">
                      <a
                        className="dropdown-item"
                        onClick={(e) => HandePushNotification(e, item)}
                      >
                        <div className="d-flex icon_bell notifictons_bells ">
                          <div className="img_msg">
                            <img
                              src={methodModel.userImg(
                                item?.addedBy_details &&
                                item?.addedBy_details.image
                              )}
                              className="small_user"
                              alt=""
                            />
                          </div>
                          <p className="ml-3 noti_data">
                            <span className="notifications_ptag" > {item?.title}</span>
                            <br />
                            <small>{GetPostedTime(item?.createdAt)}</small>
                          </p>
                        </div>
                      </a>
                    </li>
                  ))
                )}
                {!NotificationLoader && Notifications.length == 0 ? (
                  <div className="text-center text-danger">
                    <p className="pt-3">No Notification Here</p>
                  </div>
                ) : null}
                <li>
                  {NotificationTotal > 5 ? (
                    <Link
                      to="/notifications"
                      className="dropdown-item pb-3 see_All"
                    >
                      See all notification
                    </Link>
                  ) : null}
                </li>
              </ul>
            </div>
          </li>
        ) : null}
        <div className="mobile-show">
          {user.loggedIn ? (
            <>
              <div className="dropdown headerleft">
                <a
                  data-toggle="dropdown"
                  className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center image-shape"
                >
                  <img
                    alt="image"
                    src={methodModel.userImg(user.image)}
                    width={40}
                    height={40}
                    className="rounded-circle mr-1"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover prodiv_cl">
                  <div className="text-center">
                    <img
                      alt="image"
                      src={methodModel.userImg(user.image)}
                      width={50}
                      height={50}
                      className="rounded-circle drop_innerimg mb-2"
                    />
                    <div className="ml-1 ">
                      <b className="mt-2">{user.fullName}</b>
                      <p className="dropgray mb-0 text-capitalize mt-1 w-100">
                        {user.role?.name}
                      </p>
                    </div>
                  </div>

                  <Link to="/profile" className="dropdown-item has-icon">
                    <i className="far fa-user mr-1" /> Profile
                  </Link>
                  <Link to="/change" className="dropdown-item has-icon ">
                    <i className="fa fa-cog mr-1" aria-hidden="true"></i> Change
                    Password
                  </Link>
                  {user.role && user.role.id == environment.EmployerId ? (
                    <Link to="/time" className="dropdown-item has-icon ">
                      <i className="fa fa-wrench mr-1" aria-hidden="true"></i>
                      Policy Settings
                    </Link>
                  ) : null}
                  {user.role && user.role.id == environment.EmployerId ? (
                    <Link to="/table" className="dropdown-item has-icon ">
                      <i className="fa fa-clock mr-1" aria-hidden="true"></i>
                      Time Schedule
                    </Link>
                  ) : null}
                  {/* {  user.role&&user.role.id==environment.EmployerId?user.accountManager_created==true||user.directManager_created==true||user.staffIntreset==true?<Link to="/mystaff" className="dropdown-item has-icon ">
    <i className="fa fa-users mr-1" aria-hidden="true"></i> Staff
  </Link>:null:null} */}
                  {user.role && user.role.id == environment.EmployerId ? (
                    <Link to="/mystaff" className="dropdown-item has-icon ">
                      <i className="fa fa-users mr-1" aria-hidden="true"></i>{" "}
                      Staff
                    </Link>
                  ) : null}

                  {(user.role && user.role.id == environment.EmployerId) ||
                    user.role.id == environment.VirtualId ? (
                    <Link to="/notice" className="dropdown-item has-icon ">
                      <i className="fa fa-book mr-1" aria-hidden="true"></i>{" "}
                      Notice
                    </Link>
                  ) : null}

                  <Link to="/savedcard" className="dropdown-item has-icon ">
                    <i className="fa fa-credit-card mr-2"></i>Saved Cards
                  </Link>
                  <Link to="/banks" className="dropdown-item has-icon ">
                    <i className="fa fa-university mr-2"></i>ACH Payment
                  </Link>
                  <a
                    id="WantLogout"
                    onClick={() => {
                      Logout();
                      setNotificationTotal(0);
                    }}
                    className="dropdown-item has-icon"
                  >
                    <i className="fas fa-sign-out-alt mr-1" /> Logout
                  </a>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </nav>
    </>
  );
};

export default Html;
